/** @format */

import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { supabase } from "../supabaseClient";
import { useTranslation } from "react-i18next";
import { useSession } from "../context/SessionContext";
import { useNavigate } from "react-router-dom";

interface Client {
  id: string;
  phone: string;
  info: {
    nombre: string;
    mascota: {
      nombre: string;
      especie: string;
      raza: string;
      edad: number;
      peso: number;
      esterilizado: boolean;
      vacunas: Array<{ vaccine_name: string; administered_date: string }>;
      vacunas_faltantes: string[];
      proximo_calendario_vacunacion: Array<{
        vaccine_name: string;
        next_due_date: string;
      }>;
      follow_up: Array<{ comentario: string; fecha_visita: string }>;
    };
    direccion: string;
  };
}

interface ClientListProps {
  veterinarianId: string;
}

export function ClientList({ veterinarianId }: ClientListProps) {
  const { t } = useTranslation();
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const { setActivePhone, addRecentUser, refreshTokenIfNeeded, userInfo } =
    useSession();
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, [veterinarianId]);

  const fetchClients = async () => {
    refreshTokenIfNeeded();
    const { data, error } = await supabase
      .from("user")
      .select("*")
      .eq("veterinarian", veterinarianId);

    if (error) {
      console.error(t("error_fetching_clients"), error);
    } else {
      setClients(data as Client[]);
    }
  };

  const handleOpenChat = (client: Client) => {
    setActivePhone(client.phone);
    addRecentUser(client);
    navigate("/");
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("my_clients")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {clients.map((client) => (
            <Card key={client.id} className="flex flex-col">
              <CardContent className="flex items-center p-4">
                <div className="flex-1">
                  <h3 className="text-lg font-semibold">
                    {client.info.nombre}
                  </h3>
                </div>
                <Dialog>
                  <div className="flex gap-2">
                    <DialogTrigger asChild>
                      <Button
                        variant="outline"
                        onClick={() => setSelectedClient(client)}
                      >
                        {t("view_details")}
                      </Button>
                    </DialogTrigger>
                    <DialogTrigger asChild>
                      <Button
                        variant="default"
                        onClick={() => handleOpenChat(client)}
                      >
                        {t("open_chat")}
                      </Button>
                    </DialogTrigger>
                  </div>
                  <DialogContent
                    className="max-w-3xl max-h-[80vh] overflow-y-auto"
                    aria-describedby="client-info-description"
                  >
                    <DialogHeader>
                      <DialogTitle>
                        {t("client_information", {
                          clientName: client.info.nombre,
                        })}
                      </DialogTitle>
                    </DialogHeader>
                    <p id="client-info-description" className="sr-only">
                      {t("client_description", {
                        clientName: client.info.nombre,
                        petName: client.info.mascota.nombre,
                      })}
                    </p>
                    {selectedClient && (
                      <div className="space-y-4">
                        <div>
                          <h3 className="text-lg font-semibold">
                            {t("owner_information")}
                          </h3>
                          <p>
                            <strong>{t("name")}:</strong>{" "}
                            {selectedClient.info.nombre}
                          </p>
                          <p>
                            <strong>{t("address")}:</strong>{" "}
                            {selectedClient.info.direccion}
                          </p>
                        </div>
                        {userInfo.is_true_veterinarian && (
                          <>
                            <div>
                              <h3 className="text-lg font-semibold">
                                {t("pet_information")}
                              </h3>
                              <p>
                                <strong>{t("pet_name")}:</strong>{" "}
                                {selectedClient.info.mascota.nombre}
                              </p>
                              <p>
                                <strong>{t("species")}:</strong>{" "}
                                {selectedClient.info.mascota.especie}
                              </p>
                              <p>
                                <strong>{t("breed")}:</strong>{" "}
                                {selectedClient.info.mascota.raza}
                              </p>
                              <p>
                                <strong>{t("age")}:</strong>{" "}
                                {selectedClient.info.mascota.edad} {t("years")}
                              </p>
                              <p>
                                <strong>{t("weight")}:</strong>{" "}
                                {selectedClient.info.mascota.peso} kg
                              </p>
                              <p>
                                <strong>{t("sterilized")}:</strong>{" "}
                                {selectedClient.info.mascota.esterilizado
                                  ? t("yes")
                                  : t("no")}
                              </p>
                            </div>
                            <div>
                              <h3 className="text-lg font-semibold">
                                {t("vaccines")}
                              </h3>
                              <h4 className="font-semibold">
                                {t("administered_vaccines")}:
                              </h4>
                              <ul className="list-disc list-inside">
                                {selectedClient.info.mascota.vacunas ? (
                                  selectedClient.info.mascota.vacunas.map(
                                    (vaccine, index) => (
                                      <li key={index}>
                                        {vaccine.vaccine_name} -{" "}
                                        {new Date(
                                          vaccine.administered_date
                                        ).toLocaleDateString()}
                                      </li>
                                    )
                                  )
                                ) : (
                                  <li>{t("no_vaccines_administered")}</li>
                                )}
                              </ul>
                              <h4 className="font-semibold mt-2">
                                {t("upcoming_vaccines")}:
                              </h4>
                              <ul className="list-disc list-inside">
                                {selectedClient.info.mascota.proximo_calendario_vacunacion.map(
                                  (vaccine, index) => (
                                    <li key={index}>
                                      {vaccine.vaccine_name} -{" "}
                                      {new Date(
                                        vaccine.next_due_date
                                      ).toLocaleDateString()}
                                    </li>
                                  )
                                )}
                              </ul>
                              <h4 className="font-semibold mt-2">
                                {t("pending_vaccines")}:
                              </h4>
                              <ul className="list-disc list-inside">
                                {selectedClient.info.mascota.vacunas_faltantes
                                  .length > 0 ? (
                                  selectedClient.info.mascota.vacunas_faltantes.map(
                                    (vaccine, index) => (
                                      <li key={index}>{vaccine}</li>
                                    )
                                  )
                                ) : (
                                  <li>{t("no_pending_vaccines")}</li>
                                )}
                              </ul>
                            </div>
                            <div>
                              <h3 className="text-lg font-semibold">
                                {t("follow_up_history")}
                              </h3>
                              <ul className="space-y-2">
                                {selectedClient.info.mascota.follow_up.map(
                                  (entry, index) => (
                                    <li key={index} className="border-b pb-2">
                                      <p>
                                        <strong>{t("date")}:</strong>{" "}
                                        {new Date(
                                          entry.fecha_visita
                                        ).toLocaleDateString()}
                                      </p>
                                      <p>
                                        <strong>{t("comment")}:</strong>{" "}
                                        {entry.comentario}
                                      </p>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
