/** @format */

import { useEffect, useState } from "react";
import ChatWindow from "../components/ChatWindow";
import MessageInput from "../components/MessageInput";
import ChatSidebar from "../components/ChatSidebar";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Menu, X, PawPrint, Inbox } from "lucide-react";
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet";
import { useSession } from "../context/SessionContext";
import { Switch } from "../components/ui/switch";
import { useTranslation } from "react-i18next";

interface ChatPageProps {
  selectedUserPhone: string | null;
}

const ChatPage = ({ selectedUserPhone }: ChatPageProps) => {
  const { t, i18n } = useTranslation();
  const {
    session,
    myUsers,
    activePhone,
    setActivePhone,
    isTransferNotification,
    setIsTransferNotification,
    setViewingNotifications,
    language,
    refreshTokenIfNeeded,
    returnControlToAi,
    setReturnControlToAi,
  } = useSession();
  const [messages, setMessages] = useState<
    {
      from: string;
      body: string;
      type: "sent" | "received";
      sid?: string;
      dateSent?: string;
    }[]
  >([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState<any>(null);
  const [aiBlock, setAiBlock] = useState(false);
  const [dataSent, setDataSent] = useState<string | null>(null);

  useEffect(() => {
    if (returnControlToAi) {
      toggleAiBlock();
      setReturnControlToAi(false);
      setIsTransferNotification(false);
    }
  }, [returnControlToAi]);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language); // Cambiar el idioma según el contexto
    }
  }, [language, i18n]);

  useEffect(() => {
    setViewingNotifications(!activePhone);
    return () => setViewingNotifications(false);
  }, [activePhone, setViewingNotifications]);

  useEffect(() => {
    if (activePhone) {
      const selectedUser = myUsers.find((user) => user.phone === activePhone);
      setSelectedUserData(selectedUser);
      fetchMessageHistory(activePhone);
    }
  }, [activePhone, myUsers]);

  useEffect(() => {
    if (selectedUserData) {
      setAiBlock(selectedUserData.ai_block);
      if (!selectedUserData.ai_block && isTransferNotification) {
        toggleAiBlock();
        setIsTransferNotification(false);
      }
    }
  }, [selectedUserData]);

  const fetchMessageHistory = async (phoneNumber: string) => {
    try {
      const encodedTo = encodeURIComponent(phoneNumber);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/twilio/message-history?to=${encodedTo}`,
        {
          method: "GET",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
          },
        }
      );

      const data = await response.json();
      if (data.length > 0) {
        setDataSent(data[data.length - 1]?.dateSent);
        setMessages(
          data.map((msg: any) => ({
            from: msg.from,
            body: msg.body,
            dateSent: msg.dateSent,
            type: msg.from === activePhone ? "received" : "sent",
          }))
        );
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching message history:", error);
    }
  };

  const toggleAiBlock = async () => {
    if (!selectedUserData) return;

    try {
      refreshTokenIfNeeded();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/communications/toggleAiBlock/${selectedUserData.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (selectedUserData.ai_block) {
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/communications/unblock/${selectedUserData.id}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `${session!.token_type} ${session!.access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.ok) {
        setAiBlock((prev) => !prev);
      } else {
        console.error("Error toggling ai_block");
      }
    } catch (error) {
      console.error("Error toggling ai_block:", error);
    }
  };

  const fetchIncomingMessages = async () => {
    if (!activePhone || !dataSent) return;

    try {
      refreshTokenIfNeeded();
      const encodedTo = encodeURIComponent(activePhone);
      const encodedLastMessage = `&lastMessage=${encodeURIComponent(dataSent)}`;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/twilio/recent-messages?to=${encodedTo}${encodedLastMessage}`,
        {
          method: "GET",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
          },
        }
      );

      const data = await response.json();
      if (
        data.messages &&
        Array.isArray(data.messages) &&
        data.messages.length > 0
      ) {
        setDataSent(data.messages[data.messages.length - 1]?.dateSent);

        setMessages((prev) => {
          const existingMessages = new Set(
            prev.map((msg) => `${msg.body}-${msg.dateSent}`)
          );

          const newMessages = data.messages.filter(
            (msg: any) => !existingMessages.has(`${msg.body}-${msg.dateSent}`)
          );

          return [
            ...prev,
            ...newMessages.map((msg: any) => ({
              from: msg.from,
              body: msg.body,
              dateSent: msg.dateSent,
              type: msg.from === activePhone ? "received" : "sent",
            })),
          ];
        });
      }
    } catch (error) {
      console.error("Error fetching incoming messages:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchIncomingMessages();
    }, 3000);
    return () => clearInterval(interval);
  }, [activePhone, dataSent]);

  const handleSendMessage = async (message: string) => {
    if (activePhone) {
      setMessages((prev) => [
        ...prev,
        {
          from: "me",
          body: message,
          type: "sent",
          dateSent: new Date().toISOString(),
        },
      ]);

      try {
        refreshTokenIfNeeded();
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/twilio/webhook`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${session!.token_type} ${session!.access_token}`,
          },
          body: JSON.stringify({ To: activePhone, Body: message }),
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <div className="flex h-[calc(100vh-4rem)] bg-zinc-50">
      <div className="hidden md:block w-64 flex-shrink-0 overflow-y-auto">
        <ChatSidebar onSelectUser={(phone) => setActivePhone(phone)} />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-center p-4 bg-[#22C55E] text-white md:hidden">
          <h1 className="text-xl font-bold flex items-center">
            <PawPrint className="mr-2" /> {t("vet_chat")}
          </h1>
          <Sheet open={isSidebarOpen} onOpenChange={setIsSidebarOpen}>
            <SheetTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="text-white hover:bg-green-600"
              >
                {isSidebarOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="p-0 bg-zinc-50">
              <ChatSidebar onSelectUser={(phone) => setActivePhone(phone)} />
            </SheetContent>
          </Sheet>
        </div>
        {messages.length > 0 ? (
          <Card className="flex-1 m-4 border rounded-lg shadow-sm bg-white overflow-hidden">
            <CardContent className="flex flex-col h-full p-0">
              <ChatWindow messages={messages} />
              <div className="p-4 border-t bg-[#E6F7EB] flex items-center">
                <div className="flex-1">
                  <MessageInput
                    onSendMessage={(msg) => handleSendMessage(msg)}
                  />
                </div>
                <div className="flex flex-col items-center ml-4">
                  <Switch
                    checked={!aiBlock}
                    onCheckedChange={toggleAiBlock}
                    onLabel={t("ai_on")}
                    offLabel={t("ai_off")}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        ) : (
          <Card className="flex-1 m-4 border rounded-lg shadow-sm bg-white flex flex-col items-center justify-center">
            <Inbox className="h-16 w-16 text-gray-400" />
            <p className="text-gray-500 mt-4">{t("no_messages_to_display")}</p>
          </Card>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
