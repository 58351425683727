/** @format */

export const specialties: any = {
  4: {
    es: "Cardiología",
    en: "Focus on heart and cardiovascular health",
  },
  6: {
    es: "Dermatología",
    en: "Skin conditions and allergies",
  },
  9: {
    es: "Medicina general",
    en: "Family doctor",
  },
  10: {
    es: "Medicina felina",
    en: "Specialized care for feline patients",
  },
  11: {
    es: "Hospitalización",
    en: "In-patient care and monitoring",
  },
  12: {
    es: "Anestesia",
    en: "Anesthesia and pain management",
  },
  13: {
    es: "Traumatología",
    en: "Focus on bone, joint, and muscle injuries",
  },
  14: {
    es: "Exóticos",
    en: "Treatment for exotic animals",
  },
  15: {
    es: "Peluquería",
    en: "Pet grooming and care",
  },
  16: {
    es: "Urgencias",
    en: "Emergency and critical care",
  },
};
