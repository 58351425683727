/** @format */

import React from "react";
import { Button } from "./ui/button";
import { Users } from "lucide-react";

interface ClientListToggleProps {
  showClientList: boolean;
  toggleClientList: () => void;
  t: (key: string) => string;
  userInfo: { id: number };
}

const ClientListToggle: React.FC<ClientListToggleProps> = ({
  showClientList,
  toggleClientList,
  t,
  userInfo,
}) => {
  return (
    <div>
      <Button
        onClick={toggleClientList}
        variant="outline"
        className="mt-2 md:mt-4"
      >
        <Users className="w-4 h-4 mr-2" />
        {showClientList ? t("hide_clients") : t("show_clients")}
      </Button>
    </div>
  );
};

export default ClientListToggle;
