/** @format */

import React, { useState, useEffect } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Edit } from "lucide-react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";

interface PersonalInformationProps {
  userInfo: {
    name: string;
    is_true_veterinarian: boolean;
    specialty: string;
    address: string;
    nombre: string;
    image?: string;
  };
  user: {
    email?: string;
    id: string;
  };
  language: string;
  handleLanguageChange: (language: string) => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  userInfo,
  user,
  handleLanguageChange,
}) => {
  const { t } = useTranslation();
  const [avatarUrl, setAvatarUrl] = useState<string>("");

  useEffect(() => {
    const fetchAvatar = async () => {
      if (userInfo.image) {
        const filePath = `${user.id}/${userInfo.image}`;
        const { data } = supabase.storage
          .from("avatars")
          .getPublicUrl(filePath);
        if (data) {
          setAvatarUrl(`${data.publicUrl}?t=${new Date().getTime()}`); // Prevent caching
        }
      }
    };
    fetchAvatar();
  }, [user.id, userInfo.image]);

  const handleAvatarUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const fileExt = file.name.split(".").pop();
      const fileName = `${file.name}`; // Keep the original file name
      const folderName = user.id;
      const filePath = `${folderName}/${fileName}`;

      // Upload the file to Supabase storage
      const { error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(filePath, file, { upsert: true });

      if (uploadError) {
        throw uploadError;
      }

      // Update the image column in the database
      const { error: updateError } = await supabase
        .from("veterinarian")
        .update({ image: fileName })
        .eq("id", user.id);

      if (updateError) {
        throw updateError;
      }

      // Retrieve and set the new public URL
      const { data: publicUrlData } = supabase.storage
        .from("avatars")
        .getPublicUrl(filePath);

      if (publicUrlData?.publicUrl) {
        setAvatarUrl(`${publicUrlData.publicUrl}?t=${new Date().getTime()}`); // Prevent caching
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
    }
  };

  return (
    <div className="flex items-center md:items-start gap-4 md:gap-6 p-4">
      <div className="relative flex flex-col items-center gap-4">
        <Avatar className="w-20 h-20 md:w-32 md:h-32">
          <AvatarImage
            src={avatarUrl || "/placeholder.svg?height=128&width=128"}
            alt={userInfo.nombre}
          />
          <AvatarFallback>{userInfo.nombre}</AvatarFallback>
        </Avatar>
        <label
          htmlFor="avatar-upload"
          className="absolute -top-4 -right-4  p-2 rounded-full cursor-pointer"
        >
          <Edit className="w-5 h-5 text-gray-600" />
          <input
            id="avatar-upload"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleAvatarUpload}
          />
        </label>
        <div className="flex flex-col items-center">
          <label className="block text-sm text-center mb-2">
            {t("language")}
          </label>
          <div className="flex gap-4">
            <button
              onClick={() => handleLanguageChange("en")}
              className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
              title="English"
            >
              🇬🇧
            </button>
            <button
              onClick={() => handleLanguageChange("es")}
              className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
              title="Spanish"
            >
              🇪🇸
            </button>
          </div>
        </div>
      </div>
      <div className="space-y-2 md:space-y-4">
        <h2 className="text-xl md:text-2xl font-semibold">{userInfo.name}</h2>
        <p className="text-muted-foreground">
          {userInfo.is_true_veterinarian ? t("veterinarian") : t("doctor")}
        </p>
        <p className="text-muted-foreground">{userInfo.specialty}</p>
        <div className="flex flex-col gap-1 md:gap-2">
          <div className="flex items-center gap-2">
            <span className="text-sm md:text-base">{user.email}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm md:text-base">{userInfo.address}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
