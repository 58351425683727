/** @format */

import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { CalendarIcon } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { useSession } from "../context/SessionContext";
import { useTranslation } from "react-i18next";

const FormSchema = z.object({
  appointmentDate: z.date({
    required_error: "An appointment date is required.",
  }),
  appointmentTime: z.string().optional(),
});

export default function EditAppointmentForm({
  appointment,
  onUpdate,
  veterinarian,
}: any) {
  const { t } = useTranslation();
  const { session, refreshTokenIfNeeded } = useSession();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      appointmentDate: new Date(appointment.fecha),
      appointmentTime: format(new Date(appointment.fecha), "HH:mm"),
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);

  useEffect(() => {
    fetchAvailableDates();
  }, [veterinarian]);

  useEffect(() => {
    fetchAvailableTimes(new Date(appointment.fecha));
  }, [appointment.fecha, veterinarian]);

  const fetchAvailableDates = async () => {
    refreshTokenIfNeeded();
    const response: any = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/disponible/veterinarian/${session?.user.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    const uniqueDates = Array.from(
      new Set(
        data.map((appointment: any) =>
          new Date(appointment.fecha).toDateString()
        )
      )
    ).map((dateString: any) => new Date(dateString));

    setAvailableDates(uniqueDates);
  };

  const fetchAvailableTimes = async (date: Date) => {
    refreshTokenIfNeeded();
    const selectedDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );

    const response: any = await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/api/appointments/disponible/veterinarian/${
        session?.user.id
      }?date=${selectedDate.toISOString()}`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    const times = data
      .map((appointment: any) =>
        format(toZonedTime(appointment.fecha, "UTC"), "HH:mm")
      )
      .sort();

    setAvailableTimes(Array.from(new Set(times)));
  };

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const { appointmentDate, appointmentTime } = data;
    if (!appointmentTime) return;

    const [hours, minutes] = appointmentTime.split(":");

    const newAppointmentDateTimeUTC = new Date(
      Date.UTC(
        appointmentDate.getFullYear(),
        appointmentDate.getMonth(),
        appointmentDate.getDate(),
        parseInt(hours, 10),
        parseInt(minutes, 10)
      )
    );

    try {
      refreshTokenIfNeeded();
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/appointments/clear/${appointment.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/appointments/reserve`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: appointment.user_id,
            motivo: appointment.motivo,
            confirmed: false,
            trigger: true,
            veterinarian: veterinarian.id,
            dateTimeUTC: newAppointmentDateTimeUTC.toISOString(),
          }),
        }
      );

      onUpdate();
    } catch (error) {
      console.error(t("error_update"), error);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col space-y-4">
          {/* Date Selection */}
          <FormField
            control={form.control}
            name="appointmentDate"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>{t("new_appointment_date")}</FormLabel>
                <Popover open={isOpen} onOpenChange={setIsOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button variant="outline" className="w-full text-left">
                        {field.value
                          ? format(field.value, "PPP")
                          : t("pick_date")}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={(date) => {
                        field.onChange(date);
                        setIsOpen(false);
                        if (date) {
                          fetchAvailableTimes(date);
                        }
                      }}
                      disabled={(date) =>
                        !availableDates.some(
                          (availableDate) =>
                            availableDate.toDateString() === date.toDateString()
                        )
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Time Selection */}
          <FormField
            control={form.control}
            name="appointmentTime"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>{t("new_appointment_time")}</FormLabel>
                {availableTimes.length > 0 ? (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t("select_time")} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {availableTimes.map((time) => (
                        <SelectItem key={time} value={time}>
                          {time}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                ) : (
                  <p className="text-muted-foreground text-sm mt-2">
                    {t("no_available_times")}
                  </p>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" className="w-full">
          {t("save_changes")}
        </Button>
      </form>
    </Form>
  );
}
