/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { useSession } from "../context/SessionContext";
import { useTranslation } from "react-i18next";
import PersonalInformation from "../components/PersonalInformation";
import AppointmentList from "../components/AppointmentList";
import ClientListToggle from "../components/ClientListToggle";
import { ClientList } from "../components/ClientList";
import { ResponsiveCalendar } from "../components/ResponsiveCalendar";
import { AppointmentModal } from "../components/AppointmentModal";

interface Appointment {
  id: number;
  fecha: string;
  estado: string;
  motivo: string;
  user: any;
}

export default function Profile() {
  const { t, i18n } = useTranslation();
  const {
    user,
    userInfo,
    loading,
    session,
    language,
    setLanguage,
    refreshTokenIfNeeded,
  } = useSession();
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [confirmedAppointments, setConfirmedAppointments] = useState<
    Appointment[]
  >([]);
  const [showClientList, setShowClientList] = useState(false);

  useEffect(() => {
    fetchConfirmedAppointments();
  }, [userInfo]);

  useEffect(() => {
    if (language) {
      i18n
        .changeLanguage(language)
        .catch((err) => console.error("Error changing language:", err));
    }
  }, [language, i18n]);

  const handleLanguageChange = async (language: string) => {
    refreshTokenIfNeeded();
    i18n
      .changeLanguage(language)
      .catch((err) => console.error("Error changing language:", err));
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/veterinarians/updateUserTranslation`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: session?.user.id,
            newTranslation: language,
          }),
        }
      );
      const responseTranslation = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/veterinarians/getUserTranslation/${session!.user.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await responseTranslation.json();
      setLanguage(data.language);
    } catch (error) {
      console.error("Error updating language:", error);
    }
  };

  const fetchConfirmedAppointments = async () => {
    refreshTokenIfNeeded();
    if (!userInfo) return;
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/reservada/veterinarian/${session?.user.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const appointments = await response.json();

    setConfirmedAppointments(appointments);
    setAppointments(appointments);
  };

  const toggleClientList = () => {
    setShowClientList(!showClientList);
  };

  if (loading) return <div>{t("loading")}</div>;
  if (!user || !userInfo) return <div>{t("no_user_data")}</div>;

  return (
    <div
      className="container mx-auto p-4 md:p-6"
      style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
    >
      <h1 className="text-2xl md:text-3xl font-bold text-green-600 mb-4 md:mb-6">
        {t("profile")}
      </h1>
      <div className="grid gap-4 md:gap-6">
        <Card>
          <CardHeader>
            <CardTitle>{t("personal_information")}</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col md:flex-row gap-4 md:gap-6">
            <div className="flex flex-col gap-4">
              <PersonalInformation
                userInfo={userInfo}
                user={user}
                language={language}
                handleLanguageChange={handleLanguageChange}
              />
              <ClientListToggle
                showClientList={showClientList}
                toggleClientList={toggleClientList}
                t={t}
                userInfo={userInfo}
              />
            </div>
            <div className="flex flex-col mt-4 md:mt-0 md:ml-auto">
              <ResponsiveCalendar
                date={date}
                setDate={setDate}
                appointments={appointments}
              />
              <div className="mx-4">
                <AppointmentModal
                  onAppointmentAdded={fetchConfirmedAppointments}
                />
              </div>
            </div>
          </CardContent>
        </Card>

        {showClientList && <ClientList veterinarianId={userInfo.id} />}

        <AppointmentList
          confirmedAppointments={confirmedAppointments}
          t={t}
          fetchConfirmedAppointments={fetchConfirmedAppointments}
          userInfo={userInfo}
        />
      </div>
    </div>
  );
}
