export function CloseIcon() {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33398 3.33334L12.6673 12.6667M12.6673 3.33334L3.33398 12.6667"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="square"
        />
      </svg>
    );
  }
  