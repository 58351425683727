/** @format */

import React, { useState, useEffect } from "react";
import { Edit, CalendarIcon, Clock, Info, Edit2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import EditAppointmentForm from "./EditAppointmentForm";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";

interface Appointment {
  id: number;
  fecha: string;
  motivo: string;
  user: {
    nombre: string;
    mascota?: {
      nombre: string;
      especie: string;
      raza: string;
      edad: number;
      peso: number;
      esterilizado: boolean;
      vacunas?: Array<{ vaccine_name: string; administered_date: string }>;
      proximo_calendario_vacunacion?: Array<{
        vaccine_name: string;
        next_due_date: string;
      }>;
      vacunas_faltantes?: string[];
      follow_up?: Array<{ fecha_visita: string; comentario: string }>;
    };
  };
}

interface AppointmentListProps {
  confirmedAppointments: Appointment[];
  t: (key: string) => string;
  fetchConfirmedAppointments: () => void;
  userInfo: { id: number; is_true_veterinarian: boolean };
}

const AppointmentList: React.FC<AppointmentListProps> = ({
  confirmedAppointments,
  t,
  fetchConfirmedAppointments,
  userInfo,
}) => {
  return (
    <div>
      {confirmedAppointments && confirmedAppointments.length > 0 && (
        <div>
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            {t("confirmed_appointments")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {confirmedAppointments.map((appointment) => (
              <Card key={appointment.id} className="p-4">
                <CardHeader className="flex flex-row items-center gap-2 p-2">
                  <CalendarIcon className="text-green-600 w-5 h-5" />
                  <CardTitle className="text-sm md:text-md">
                    {new Date(appointment.fecha).toLocaleDateString()}
                  </CardTitle>
                  <Clock className="text-green-600 w-5 h-5 ml-auto" />
                  <span className="text-sm">
                    {new Date(appointment.fecha).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <Popover>
                    <PopoverTrigger asChild>
                      <button className="ml-2">
                        <Edit2 className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="p-4 bg-white rounded-lg shadow-lg w-[calc(100vw-2rem)] max-w-md mx-auto left-0 right-0">
                      <EditAppointmentForm
                        appointment={appointment}
                        onUpdate={fetchConfirmedAppointments}
                        veterinarian={userInfo}
                      />
                    </PopoverContent>
                  </Popover>
                  {userInfo.is_true_veterinarian &&
                    appointment.user.mascota && (
                      <Popover>
                        <PopoverTrigger asChild>
                          <button className="ml-2">
                            <Info className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="p-4 bg-white rounded-lg shadow-lg w-[calc(100vw-2rem)] max-w-md mx-auto left-0 right-0">
                          <h3 className="text-lg font-semibold mb-4">
                            {t("pet_details")}
                          </h3>
                          <div className="space-y-4 text-sm">
                            <div className="grid grid-cols-2 gap-2">
                              <p>
                                <span className="font-semibold">
                                  {t("pet_name")}:
                                </span>{" "}
                                {appointment.user.mascota.nombre}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_species")}:
                                </span>{" "}
                                {appointment.user.mascota.especie}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_breed")}:
                                </span>{" "}
                                {appointment.user.mascota.raza}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_age")}:
                                </span>{" "}
                                {appointment.user.mascota.edad} {t("years")}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_weight")}:
                                </span>{" "}
                                {appointment.user.mascota.peso} kg
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_sterilized")}:
                                </span>{" "}
                                {appointment.user.mascota.esterilizado
                                  ? t("yes")
                                  : t("no")}
                              </p>
                            </div>
                            <div>
                              <p className="font-semibold mb-1">
                                {t("administered_vaccines")}:
                              </p>
                              <ul className="list-disc list-inside">
                                {appointment.user.mascota.vacunas?.map(
                                  (vaccine, index) => (
                                    <li key={index}>
                                      {vaccine.vaccine_name} -{" "}
                                      {new Date(
                                        vaccine.administered_date
                                      ).toLocaleDateString()}
                                    </li>
                                  )
                                ) || t("no_vaccines")}
                              </ul>
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    )}
                </CardHeader>
                <CardContent className="p-2">
                  <div className="flex flex-col justify-start">
                    <h3 className="text-sm md:text-md font-semibold">
                      {t("name")}: {appointment.user.nombre}
                    </h3>
                    <p className="text-sm font-semibold">
                      {t("reason")}: {appointment.motivo}
                    </p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentList;
