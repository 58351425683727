import React, { useCallback, useEffect, useState } from 'react';
import { useSession } from '../context/SessionContext';
import { AnimatePresence, motion } from "framer-motion";
import {
  LiveKitRoom,
  useVoiceAssistant,
  BarVisualizer,
  RoomAudioRenderer,
  DisconnectButton,
  useConnectionState,
} from "@livekit/components-react";
import { useRoomInfo, useRoomContext, useParticipants } from "@livekit/components-react";
import { NoAgentNotification } from '../components/NoAgentNotification';
import toast from 'react-hot-toast';
import { CloseIcon } from '../components/CloseIcon';
import { useTranslation } from 'react-i18next';

const ParticipantForm = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [language, setLanguage] = useState("");
  const [assistant, setAssistant] = useState("");
  const [context, setContext] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");

  return (
    <div className="bg-black p-6 rounded-lg shadow-lg max-w-md w-full">
      <div className="space-y-4">
        <div>
          <label className="block text-sm mb-1">{t('form_name_label')} *</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 bg-white/10 rounded-md"
            placeholder={t('form_name_placeholder')}
          />
        </div>
        <div>
          <label className="block text-sm mb-1">{t('form_language_label')} *</label>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="w-full px-3 py-2 bg-white/10 text-gray-400 rounded-md"
          >
            <option value="" disabled className="text-white bg-black">
              {t('form_language_placeholder')}
            </option>
            <option value="en" className="text-white bg-black">{t('language_english')}</option>
            <option value="es" className="text-white bg-black">{t('language_spanish')}</option>
            <option value="fr" className="text-white bg-black">{t('language_french')}</option>
            <option value="de" className="text-white bg-black">{t('language_german')}</option>
            <option value="zh" className="text-white bg-black">{t('language_chinese')}</option>
            <option value="ar" className="text-white bg-black">{t('language_arabic')}</option>
            <option value="hi" className="text-white bg-black">{t('language_hindi')}</option>
            <option value="ja" className="text-white bg-black">{t('language_japanese')}</option>
          </select>
        </div>
        <div>
          <label className="block text-sm mb-1">{t('form_assistant_label')} *</label>
          <select
            value={assistant}
            onChange={(e) => setAssistant(e.target.value)}
            className="w-full px-3 py-2 bg-white/10 text-gray-400 rounded-md"
          >
            <option value="" disabled className="text-white bg-black">
              {t('form_assistant_placeholder')}
            </option>
            <option value="hospital" className="text-white bg-black">{t('assistant_hospital')}</option>
            <option value="veterinarian" className="text-white bg-black">{t('assistant_veterinarian')}</option>
          </select>
        </div>
        <div>
          <label className="block text-sm mb-1">{t('form_system_prompt_label')}</label>
          <textarea
            value={systemPrompt}
            onChange={(e) => setSystemPrompt(e.target.value)}
            className="w-full px-3 py-2 bg-white/10 rounded-md h-24"
            placeholder={t('form_system_prompt_placeholder')}
          />
        </div>
        <div>
          <label className="block text-sm mb-1">{t('form_context_label')}</label>
          <textarea
            value={context}
            onChange={(e) => setContext(e.target.value)}
            className="w-full px-3 py-2 bg-white/10 rounded-md h-24"
            placeholder={t('form_context_placeholder')}
          />
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => onSubmit({ name, language, assistant, context, systemPrompt })}
            disabled={!name.trim() || !language.trim() || !assistant.trim()}
            className="flex-1 uppercase px-4 py-2 bg-white text-black rounded-md disabled:opacity-50"
          >
            {t('form_start_button')}
          </button>
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-white/10 rounded-md"
          >
            {t('form_cancel_button')}
          </button>
        </div>
      </div>
    </div>
  );
};

const TextArea = ({ value, onChange, onSave }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-4">
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-[calc(100vh-600px)] px-3 py-2 text-gray-100 border rounded-lg focus:outline-none bg-gray-800"
        placeholder={t('textarea_placeholder')}
      />
      <button
        onClick={onSave}
        className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
      >
        {t('textarea_save_button')}
      </button>
    </div>
  );
};

export default function Settings() {
  const { t } = useTranslation();
  const { user, userInfo, loading, session, refreshTokenIfNeeded } = useSession();
  const [connectionDetails, updateConnectionDetails] = useState(undefined);
  const [agentState, setAgentState] = useState("disconnected");
  const [showForm, setShowForm] = useState(false);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('assistant');
  const [textAreaContent, setTextAreaContent] = useState('');

  const handleFormSubmit = useCallback(async (formData) => {
    try {
      setParticipantInfo(formData);
      setShowForm(false);
      localStorage.setItem('participantInfo', JSON.stringify(formData));
      const participantIdentity = `voice_assistant_user_${Math.floor(Math.random() * 10_000)}`;
      const roomName = `voice_assistant_room_${Math.floor(Math.random() * 10_000)}`;
      const url = new URL(process.env.REACT_APP_BACKEND_URL + "/api/livekit/token");

      url.searchParams.append("participantName", formData.name ?? "Anonymous");
      url.searchParams.append("roomName", roomName);
      if (formData.context) {
        url.searchParams.append("context", formData.context);
      }
      if (formData.systemPrompt) {
        url.searchParams.append("systemPrompt", formData.systemPrompt);
      }
      if (formData.language) {
        url.searchParams.append("language", formData.language);
      }

      if (formData.assistant) {
        url.searchParams.append("assistant", formData.assistant);
      }

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch connection details: ${response.statusText}`);
      }
      const connectionDetailsData = await response.json()
      updateConnectionDetails(connectionDetailsData);
    } catch (error) {
      console.error("Error fetching connection details:", error);
    }
  }, []);

  const onConnectButtonClicked = useCallback(() => {
    const formData = {};
    const participantInfo = JSON.parse(localStorage.getItem('participantInfo'));
    if (participantInfo) {
      formData.name = participantInfo.name;
      formData.language = participantInfo.language;
      formData.assistant = participantInfo.assistant;
      formData.context = participantInfo.context;
      formData.systemPrompt = participantInfo.systemPrompt;
    }
    handleFormSubmit(formData);
  }, []);

  const onConfigureButtonClicked = useCallback(() => {
    setShowForm(true);
  }, []);

  const fetchData = async () => {
    try {
      refreshTokenIfNeeded()
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/communications/getBotInstructions/${userInfo?.company_id}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `${session.token_type} ${session.access_token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      const data = await response.text();
      const formattedData = formatTextContent(data);
      setTextAreaContent(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatTextContent = (text) => {
    let formattedText = text.replace(/^"|"$/g, '');
    formattedText = formattedText.replace(/\\n/g, '\n');
    formattedText = formattedText.replace(/\n{3,}/g, '\n\n');
    return formattedText;
  };

  const saveData = async () => {
    try {
      refreshTokenIfNeeded()
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/communications/updateBotInstructions`,
        {
          method: 'PATCH',
          headers: {
            'Authorization': `${session.token_type} ${session.access_token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company_id: userInfo.company_id,
            instructions: textAreaContent
          })
        }
      );

      if (response.ok) {
        toast.success(t('save_success'));
        fetchData();
      } else {
        toast.error(t('save_error'));
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  useEffect(() => {
    if (activeTab === 'textArea') {
      fetchData();
    }
  }, [activeTab]);

  if (loading) return <div>{t('loading')}</div>;
  if (!user || !userInfo) return <div>{t('no_user_data')}</div>;

  return (
    <div className="container mx-auto p-4 md:p-6" style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
      <h1 className="text-2xl md:text-3xl font-bold text-green-600 mb-4 md:mb-6">{t('settings')}</h1>
      <div className="flex mb-4">
        <button
          className={`px-4 py-2 ${activeTab === 'assistant' ? 'bg-green-500 text-white' : 'bg-gray-200'} rounded-l-md`}
          onClick={() => setActiveTab('assistant')}
        >
          {t('tab_voice_app')}
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'textArea' ? 'bg-green-500 text-white' : 'bg-gray-200'} rounded-r-md`}
          onClick={() => setActiveTab('textArea')}
        >
          {t('tab_bot_instructions')}
        </button>
      </div>
      {activeTab === 'assistant' && (
        <div className="h-auto flex items-center bg-white text-white">
          <div className="w-2/4 flex justify-center items-center">
            <div className="bg-white px-6 py-4 rounded-lg shadow-md border">
              <div className="flex items-center mb-4">
                <img
                  src="/scribe.png"
                  alt="Profile"
                  className="rounded-full w-10 h-10 mr-3"
                />
                <div>
                  <p className="text-sm text-gray-500">{t('reception')}</p>
                  <h2 className="text-xl text-black font-bold">María</h2>
                </div>
              </div>
              <div>
                <p className="text-sm text-black mb-2">{t('skills')}</p>
                <ul className="list-disc list-inside text-sm text-gray-700">
                  <li>{t('skill_respond_questions')}</li>
                  <li>{t('skill_manage_appointments')}</li>
                  <li>{t('skill_no_sensitive_info')}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex-1 flex flex-col justify-center items-center">
            <AnimatePresence>
              {showForm && !connectionDetails && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 bg-black/50 grid place-items-center p-4 z-50"
                >
                  <ParticipantForm
                    onSubmit={handleFormSubmit}
                    onCancel={() => setShowForm(false)}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <LiveKitRoom
              token={connectionDetails?.participantToken}
              serverUrl={connectionDetails?.serverUrl}
              connect={connectionDetails !== undefined}
              audio={true}
              video={false}
              onMediaDeviceFailure={onDeviceFailure}
              onDisconnected={() => {
                updateConnectionDetails(undefined);
                setParticipantInfo(null);
              }}
              className="flex flex-col items-center"
            >
              <div className="flex justify-center items-center p-4">
                <div className="w-60 h-60 rounded-full overflow-hidden border-4 border-black">
                  <img
                    src="/scribe.png"
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>

              <RoomInfoComponent />
              <SimpleVoiceAssistant
                onStateChange={setAgentState}
                participantInfo={participantInfo}
              />
              <ControlBar
                onConnectButtonClicked={onConnectButtonClicked}
                onConfigureButtonClicked={onConfigureButtonClicked}
                agentState={agentState}
              />
              <RoomAudioRenderer />
              <NoAgentNotification state={agentState} />
            </LiveKitRoom>
          </div>
        </div>
      )}
      {activeTab === 'textArea' && (
        <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
          <TextArea
            value={textAreaContent}
            onChange={setTextAreaContent}
            onSave={saveData}
          />
        </div>
      )}
    </div>
  );
}

const SimpleVoiceAssistant = ({ onStateChange, participantInfo }) => {
  const { state, audioTrack } = useVoiceAssistant();
  const { t } = useTranslation();

  useEffect(() => {
    onStateChange(state);
  }, [onStateChange, state]);

  if (state === "disconnected") {
    return null;
  }

  return (
    <div className="flex h-[100px] w-[300px] mb-8 mx-auto">
      <BarVisualizer
        state={state}
        barCount={5}
        trackRef={audioTrack}
        className="agent-visualizer"
        options={{ minHeight: 32 }}
      />
    </div>
  );
}

function ControlBar(props) {
  const { t } = useTranslation();

  return (
    <div className="relative h-[100px] w-[300px]">
      <AnimatePresence>
        {props.agentState === "disconnected" && (
          <div className='flex flex-row justify-center gap-4'>
            <motion.button
              initial={{ opacity: 0, top: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, top: "-10px" }}
              transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
              className="uppercase px-4 py-2 bg-black text-white rounded-md"
              onClick={() => props.onConnectButtonClicked()}
            >
              {t('control_bar_start')}
            </motion.button>
            <motion.button
              initial={{ opacity: 0, top: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, top: "-10px" }}
              transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
              className="uppercase px-4 py-2 bg-black text-white rounded-md"
              onClick={() => props.onConfigureButtonClicked()}
            >
              {t('control_bar_configure')}
            </motion.button>
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {props.agentState !== "disconnected" &&
          props.agentState !== "connecting" && (
            <motion.div
              initial={{ opacity: 0, top: "10px" }}
              animate={{ opacity: 1, top: 0 }}
              exit={{ opacity: 0, top: "-10px" }}
              transition={{ duration: 0.4, ease: [0.09, 1.04, 0.245, 1.055] }}
              className="flex h-8 w-full absolute left-1/2 -translate-x-1/2  justify-center"
            >
              <DisconnectButton>
                <CloseIcon />
              </DisconnectButton>
            </motion.div>
          )}
      </AnimatePresence>
    </div>
  );
}

function RoomInfoComponent() {
  const room = useRoomContext();
  const participants = useParticipants();
  const roomInfo = useRoomInfo({ room: room || null });
  const connectionState = useConnectionState(room || null);

  useEffect(() => {
    console.log("Current Room:", room);
    console.log("Current Participants:", participants);
    console.log("Current Room Info:", roomInfo);
    console.log("Current Connection State:", connectionState);
  }, [room, participants, roomInfo, connectionState]);

  return null;
}

function onDeviceFailure(error) {
  console.error(error);
  alert(
    "Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab"
  );
}
