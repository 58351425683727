/** @format */

"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { CalendarIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { cn } from "../lib/utils";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import { useSession } from "../context/SessionContext";
import { useTranslation } from "react-i18next";

const FormSchema = z.object({
  selectedUserId: z.string().nonempty("A user selection is required."),
  appointmentDate: z.date({
    required_error: "An appointment date is required.",
  }),
  appointmentTime: z.string({
    required_error: "An appointment time is required.",
  }),
  motivo: z.string().min(1, "Motivo is required"),
  selectedSpecialtyId: z
    .string()
    .nonempty("A specialty selection is required."),
  selectedVeterinarianId: z
    .string()
    .nonempty("A veterinarian selection is required."),
});

interface CalendarFormProps {
  onAppointmentAdded: () => void;
}

export function CalendarForm({ onAppointmentAdded }: CalendarFormProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      selectedUserId: "",
      appointmentDate: undefined,
      appointmentTime: "",
      motivo: "",
      selectedVeterinarianId: "",
    },
  });
  const { session, refreshTokenIfNeeded, userInfo } = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [specialties, setSpecialties] = useState<any[]>([]);
  const [veterinarians, setVeterinarians] = useState<any[]>([]);
  const [noVeterinarians, setNoVeterinarians] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      refreshTokenIfNeeded();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/${session?.user.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
          },
        }
      );
      const data = await response.json();

      const usersList = data.map((user: any) => ({
        id: user.id,
        displayName: userInfo.is_true_veterinarian
          ? `${user.info.nombre} - ${user.info.mascota.nombre}`
          : `${user.info.nombre}`,
      }));
      setUsers(usersList);
    };

    const fetchSpecialties = async () => {
      refreshTokenIfNeeded();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/veterinarians/specialties`,
        {
          method: "GET",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
          },
        }
      );
      const data = await response.json();
      setSpecialties(data);
    };

    const fetchAvailableDates = async () => {
      refreshTokenIfNeeded();
      const response: any = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/appointments/disponible/veterinarian/${session?.user.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      const uniqueDates = Array.from(
        new Set(
          data.map((appointment: any) =>
            new Date(appointment.fecha).toDateString()
          )
        )
      ).map((dateString: any) => new Date(dateString));

      setAvailableDates(uniqueDates);
    };

    fetchUsers();
    fetchSpecialties();
    fetchAvailableDates();
  }, []);

  const fetchAvailableTimes = async (date: any) => {
    refreshTokenIfNeeded();
    const selectedDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const response: any = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/disponible/veterinarian/${session?.user.id}?date=${selectedDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    const times = data
      .map((appointment: any) =>
        format(toZonedTime(appointment.fecha, "UTC"), "HH:mm")
      )
      .sort();

    setAvailableTimes(Array.from(new Set(times))); // Ensure unique times
  };

  const handleSpecialtyChange = async (specialtyId: string) => {
    refreshTokenIfNeeded();
    form.setValue("selectedVeterinarianId", ""); // Reset veterinarian selection
    form.setValue("selectedSpecialtyId", specialtyId);

    const selectedSpecialty = specialties.find(
      (spec) => spec.id.toString() === specialtyId
    )?.specialty_name;

    if (!selectedSpecialty) {
      console.error("Selected specialty does not have a valid name.");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/veterinarians/specialty/${selectedSpecialty}`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
        },
      }
    );
    const data = await response.json();

    setVeterinarians(data);
    setNoVeterinarians(data.length === 0);
  };

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    refreshTokenIfNeeded();
    const {
      selectedUserId,
      appointmentDate,
      appointmentTime,
      motivo,
      selectedVeterinarianId,
    } = data;
    const [hours, minutes] = appointmentTime.split(":");

    const appointmentDateTimeUTC = new Date(
      Date.UTC(
        appointmentDate.getFullYear(),
        appointmentDate.getMonth(),
        appointmentDate.getDate(),
        parseInt(hours, 10),
        parseInt(minutes, 10)
      )
    );

    const selectedVeterinarian = veterinarians.find(
      (vet) => vet.id === selectedVeterinarianId
    );

    if (!selectedVeterinarian || !selectedVeterinarian.name) {
      console.error("Selected veterinarian does not have a valid name.");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/reserve`,
      {
        method: "PATCH",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: selectedUserId,
          motivo: motivo,
          confirmed: true,
          trigger: true,
          veterinarian: selectedVeterinarian.name,
          dateTimeUTC: appointmentDateTimeUTC.toISOString(),
        }),
      }
    );
    const updatedAppointments = await response.json();

    const newAppointmentId = updatedAppointments?.[0]?.id;
    if (!newAppointmentId) {
      console.error("Failed to retrieve the new appointment ID.");
      return;
    }

    const responseVet = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/veterinarians/${selectedVeterinarianId}/appointments`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
        },
      }
    );
    const vetData = await responseVet.json();

    const updatedAppointmentsArray = vetData.appointments
      ? [...vetData.appointments, newAppointmentId]
      : [newAppointmentId];

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/veterinarians/${selectedVeterinarianId}/appointments`,
      {
        method: "PATCH",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ appointments: updatedAppointmentsArray }),
      }
    );

    console.log(
      "Appointment booked successfully and veterinarian's appointments updated."
    );
    form.reset();
    onAppointmentAdded();
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid grid-cols-1 sm:grid-cols-2 gap-4"
      >
        {/* User Selection */}
        <FormField
          control={form.control}
          name="selectedUserId"
          render={({ field }) => {
            const selectedDisplayName =
              users.find((user) => user.id.toString() === field.value)
                ?.displayName || "Select a user";
            return (
              <FormItem>
                <FormLabel>{t("select_user")}</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue>{selectedDisplayName}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {users.map((user) => (
                      <SelectItem key={user.id} value={user.id.toString()}>
                        {user.displayName}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        {/* Specialty Selection */}
        {form.watch("selectedUserId") && (
          <FormField
            control={form.control}
            name="selectedSpecialtyId"
            render={({ field }) => {
              const selectedSpecialty =
                specialties.find((spec) => spec.id.toString() === field.value)
                  ?.specialty_name || "Select a specialty";
              return (
                <FormItem>
                  <FormLabel>{t("select_specialty")}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      handleSpecialtyChange(value);
                    }}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue>{selectedSpecialty}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {specialties.map((spec) => (
                        <SelectItem key={spec.id} value={spec.id.toString()}>
                          {spec.specialty_name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        )}

        {/* Veterinarian Selection */}
        {form.watch("selectedSpecialtyId") &&
          (veterinarians.length > 0 ? (
            <FormField
              control={form.control}
              name="selectedVeterinarianId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {userInfo.is_true_veterinarian
                      ? t("select_veterinarian")
                      : t("select_doctor")}
                  </FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue>
                          {userInfo.is_true_veterinarian
                            ? t("select_veterinarian")
                            : t("select_doctor")}
                        </SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {veterinarians.map((vet) => (
                        <SelectItem key={vet.id} value={vet.id.toString()}>
                          {vet.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : (
            <p className="col-span-2 text-sm text-red-500">
              {userInfo.is_true_veterinarian
                ? t("no_veterinarian_available_for_specialty")
                : t("no_doctor_available_for_specialty")}
            </p>
          ))}

        {/* Date Selection */}
        {form.watch("selectedVeterinarianId") && (
          <FormField
            control={form.control}
            name="appointmentDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("appointment_date")}</FormLabel>
                <Popover open={isOpen} onOpenChange={setIsOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>{t("pick_date")}</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={(date) => {
                        field.onChange(date);
                        setIsOpen(false);
                        if (date) {
                          fetchAvailableTimes(date);
                        }
                      }}
                      disabled={(date) =>
                        !availableDates.some(
                          (availableDate) =>
                            availableDate.toDateString() === date.toDateString()
                        )
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* Time Selection */}
        {form.watch("appointmentDate") && (
          <FormField
            control={form.control}
            name="appointmentTime"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("appointment_time")}</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t("select_time")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {availableTimes.length > 0 ? (
                      availableTimes.map((time) => (
                        <SelectItem key={time} value={time}>
                          {time}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem disabled value="no-available-times">
                        {t("no_available_time")}
                      </SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        )}

        {/* Motivo Input */}
        <FormField
          control={form.control}
          name="motivo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("reason")}</FormLabel>
              <FormControl>
                <Input placeholder={t("appointment_reason")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="col-span-2">
          <Button type="submit" className="w-full">
            {t("book_appointment")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
