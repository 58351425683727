/** @format */

import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./components/theme-provider";
import { SessionProvider } from "./context/SessionContext";
import Navbar from "./components/Navbar";
import ChatPage from "./pages/ChatPage";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PrivateRoute from "./components/PrivateRoute";
import Settings from "./pages/Settings";
import { Toaster } from "react-hot-toast";
import { useState } from "react";

function App() {
  const [selectedUserPhone, setSelectedUserPhone] = useState<string | null>(
    null
  );

  const handleUserSelect = (phone: string) => {
    setSelectedUserPhone(phone);
  };

  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
      <SessionProvider>
        <div className="min-h-screen bg-background font-sans antialiased">
          <Router>
            <Navbar onUserSelect={handleUserSelect} />
            <main className="flex-1 overflow-y-auto">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <ChatPage selectedUserPhone={selectedUserPhone} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </main>
          </Router>
        </div>
      </SessionProvider>
    </ThemeProvider>
  );
}

export default App;
