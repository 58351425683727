/** @format */

import { ScrollArea } from "./ui/scroll-area";
import { PawPrint } from "lucide-react";
import { useRef, useEffect } from "react";

interface ChatWindowProps {
  messages: { from: string; body: string }[];
}

const ChatWindow = ({ messages }: ChatWindowProps) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const formatMessage = (message: string) => {
    const cleanedMessage = message.replace(/^\n+/, "").trim();
    const lines = cleanedMessage.split("\n");
    return lines
      .map((line) => {
        const trimmedLine = line.trim();
        if (trimmedLine.endsWith(":")) {
          return `\n${trimmedLine}\n`;
        } else if (trimmedLine.startsWith("-") || /^\d+\./.test(trimmedLine)) {
          return `  ${trimmedLine}\n`;
        }
        return `${trimmedLine}\n`;
      })
      .join("");
  };

  return (
    <ScrollArea className="h-full bg-zinc-50">
      <div className="flex flex-col gap-2 p-4">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${
              msg.from !== "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`p-3 rounded-lg max-w-[80%] ${
                msg.from !== "user"
                  ? "bg-[#22C55E] text-white"
                  : "bg-white border border-zinc-200 shadow-sm"
              }`}
            >
              {msg.from === "user" ? (
                <>
                  <PawPrint className="h-4 w-4 mb-1 text-[#22C55E]" />
                  <p className="text-sm break-words">{msg.body}</p>
                </>
              ) : (
                <pre className="text-sm whitespace-pre-wrap font-sans">
                  {formatMessage(msg.body)}
                </pre>
              )}
            </div>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>
    </ScrollArea>
  );
};

export default ChatWindow;
