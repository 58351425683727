import React from 'react';
import { Calendar } from '../components/ui/calendar';
import { PopoverAppointment } from '../components/PopoverAppointment';
import { cn } from "../lib/utils";

interface ResponsiveCalendarProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  appointments: any[];
}

interface DayProps {
  date: Date;
  displayMonth?: Date;
  isSelected?: boolean;
  isToday?: boolean;
  disabled?: boolean;
}

export function ResponsiveCalendar({ date, setDate, appointments }: ResponsiveCalendarProps) {
  return (
    <div className="w-full max-w-sm mx-auto">
      <Calendar
        mode="single"
        selected={date}
        onSelect={setDate}
        className="rounded-md border"
        components={{
          Day: ({ date, ...props }: DayProps) => {
            const appointmentsForDay = appointments.filter(
              (appointment) =>
                new Date(appointment.fecha).toDateString() ===
                date.toDateString()
            );
            if (appointmentsForDay.length > 0) {
              return (
                <PopoverAppointment
                  date={date}
                  appointments={appointmentsForDay}
                />
              );
            }
            return (
              <button
                className={cn(
                  "h-8 w-8 p-0 font-normal aria-selected:opacity-100",
                  props.isSelected && "bg-green-100 text-green-900",
                  props.isToday && "bg-green-200 text-green-900 font-bold"
                )}
              >
                <time dateTime={date.toISOString()}>{date.getDate()}</time>
              </button>
            );
          },
        }}
      />
    </div>
  );
}