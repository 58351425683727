import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // Para cargar archivos de traducción
  .use(LanguageDetector) // Para detectar el idioma del navegador
  .use(initReactI18next) // Enlace con React
  .init({
    fallbackLng: 'es', // Idioma por defecto
    supportedLngs: ['en', 'es'], // Idiomas soportados
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Ruta de los archivos de traducción
    },
    interpolation: {
      escapeValue: false, // React ya escapa por defecto
    },
  });

export default i18n;
