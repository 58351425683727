// src/components/PrivateRoute.js
import { Navigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext';

const PrivateRoute = ({ children }) => {
  const { session, loading } = useSession();

  // Show nothing while loading user session
  if (loading) return null;

  // Redirect to login if no session
  return session ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
