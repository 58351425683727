import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Button } from "./ui/button";
import { CalendarForm } from './CalendarForm';
import { useTranslation } from "react-i18next";

interface AppointmentModalProps {
  onAppointmentAdded: () => void;
}

export function AppointmentModal({ onAppointmentAdded }: AppointmentModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-full sm:w-[278px]">{t('new_appointment')}</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[550px] w-11/12 max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t('new_appointment')}</DialogTitle>
        </DialogHeader>
        <CalendarForm onAppointmentAdded={onAppointmentAdded} />
      </DialogContent>
    </Dialog>
  );
}
