/** @format */

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PawPrint, User, HeartPulse } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import { useSession } from "../context/SessionContext";
import { supabase } from "../supabaseClient";
import { useTranslation } from "react-i18next";

const Navbar = ({
  onUserSelect,
}: {
  onUserSelect: (phone: string) => void;
}) => {
  const { t } = useTranslation();
  const { user, userInfo } = useSession();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (!error) {
        navigate("/login", { replace: true });
      }
    } catch (err) {
      console.error("Error during sign-out:", err);
    }
  };

  if (!user) return null;

  return (
    <nav className="bg-background border-b">
      <div className="w-auto mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Link
              to="/"
              className="flex items-center text-2xl font-bold text-primary"
            >
              {userInfo && userInfo.is_true_veterinarian ? (
                <PawPrint className="h-8 w-8 mr-2" />
              ) : (
                <HeartPulse className="h-8 w-8 mr-2" />
              )}
              <span>
                {userInfo && userInfo.is_true_veterinarian
                  ? t("omnivet")
                  : t("omnicare")}
              </span>
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            {/* Menú de usuario */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <User className="h-5 w-5" />
                  <span className="sr-only">{t("open_user_menu")}</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem asChild>
                  <Link to="/profile">{t("profile")}</Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <Link to="/settings">{t("settings")}</Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleSignOut}>
                  {t("sign_out")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
